var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pool)?_c('div',[_c('div',[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Swap fee")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Swapping fee, percentage of traded amount."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
            `${(_vm.pool.swapFee * 100).toLocaleString(undefined, { maximumFractionDigits: 18 })}%`
          )}}),(!_vm.details)?_c('span',[_vm._v(" ...")]):_vm._e(),_c('a',{staticClass:"d-flex float-right",on:{"click":function($event){_vm.details = !_vm.details}}},[_c('span',{staticClass:"flex-auto text-gray",domProps:{"textContent":_vm._s(_vm.detailsText)}}),_c('Icon',{staticClass:"text-gray",class:{ 'mt-1': _vm.details },attrs:{"name":_vm.details ? 'arrow-up' : 'arrow-down'}})],1)],1),(_vm.details)?_c('div',[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Exit fee")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Fee charged when removing liquidity from the pool."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(_vm.pool.info.exit_fee * 100).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}%`
            )}})],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Arb tax")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Additional fee that is charged as a percentage of arbitrageur profit. It is assumed that arbitrageurs buy from the pool in order to sell elsewhere and make a profit from the difference in prices."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(_vm.pool.info.arb_profit_tax * 100).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}%`
            )}})],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Leverage profit tax")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Percentage of profit charged from a leveraged position when it is closed (if the close price is higher than the open price)."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(_vm.pool.info.leverage_profit_tax * 100).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}%`
            )}})],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Leverage token tax")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Percentage of the redeemed amount charged when redeeming a leveraged token."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(_vm.pool.info.leverage_token_tax * 100).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}%`
            )}})],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Base interest rate")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Base interest rate charged from leveraged positions. If there are many leveraged positions, the rate can increase depending on utilization."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(_vm.pool.info.base_interest_rate * 100).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}%`
            )}})],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Utilization ratio")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"What share of the pool's capacity is used for borrowing by leverage traders."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(+(this.utilizationRatio * 100).toFixed(2)).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}%`
            )}})],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Actual interest rate")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Actual interest rate paid by leverage traders, it grows with utilization."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(+(this.actualInterestRate * 100).toFixed(2)).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}%`
            )}})],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Borrowed amounts")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Amounts borrowed by leverage traders from the pool to finance their leveraged positions."}}),_c('span',{staticClass:"text-white ml-2"},[_c('Amount',{attrs:{"value":_vm.borrowedAmounts.x,"asset":_vm.pool.x_asset}}),_c('Ticker',{attrs:{"asset":_vm.pool.x_asset}}),_vm._v(" + "),_c('Amount',{attrs:{"value":_vm.borrowedAmounts.y,"asset":_vm.pool.y_asset}}),_c('Ticker',{attrs:{"asset":_vm.pool.y_asset}})],1)],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Return on capital from lending alone")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Earnings from providing capital to leverage traders alone."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(+(
                this.borrowedAmounts.borrowed_to_assets *
                this.actualInterestRate *
                100
              ).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 18 })}%`
            )}})],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Token weights")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Relative weights of the two tokens in the pool."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(_vm.pool.info.alpha * 100).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}% / ${((1 - _vm.pool.info.alpha) * 100).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}%`
            )}})],1),_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Pool leverage")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"A multiplier that makes the pool behave like it has more liquidity than it really has. The full multiplier is applied when the pool is balanced and it decreases as the pool goes out of balance."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${_vm.pool.info.pool_leverage.toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}`
            )}})],1),(_vm.pool.info.mid_price)?_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Mid-price")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"Mid-price for stablecoin pools."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(_vm.pool.info.mid_price * _vm.priceMultiplier).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}`
            )}})],1):_vm._e(),(_vm.pool.info.mid_price)?_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"label-padding"},[_vm._v("Price deviation")]),_c('Tooltip',{staticStyle:{"display":"inline-block"},attrs:{"tooltip-description":"This factor indicates how far the price is allowed to deviate from the mid-price."}}),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${(+_vm.pool.info.price_deviation).toLocaleString(undefined, {
                maximumFractionDigits: 18
              })}`
            )}})],1):_vm._e(),_c('div',{staticClass:"d-block"},[_c('label',[_vm._v("Price range")]),_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(
              `${_vm.pool.p_min &&
                (+(_vm.pool.p_min * _vm.priceMultiplier).toPrecision(6)).toLocaleString(undefined, {
                  maximumFractionDigits: 18
                })} to ${(+(_vm.pool.p_max * _vm.priceMultiplier).toPrecision(
                6
              )).toLocaleString(undefined, { maximumFractionDigits: 18 })}`
            )}})]),_c('div',{staticClass:"d-block"},[_c('label',[_vm._v("LP shares symbol")]),(_vm.sharesSymbol)?_c('span',{staticClass:"text-white ml-2",domProps:{"textContent":_vm._s(_vm.sharesSymbol)}}):_c('a',{staticClass:"text-white ml-2",attrs:{"href":_vm.registerSymbolHref}},[_vm._v("Register symbol "+_vm._s(_vm.proposedSharesSymbol))])]),_c('div',{staticClass:"d-block"},[_c('a',{attrs:{"href":_vm._governanceLink(_vm.pool.address),"target":"_blank","title":"Go to governance website"}},[_vm._v(" Change these parameters in governance "),_c('Icon',{staticClass:"ml-1",attrs:{"name":"external-link","size":"18"}})],1)])]):_vm._e()]),(_vm.pool.hasLiquidity())?_c('div',[_c('label',{staticClass:"d-block"},[_vm._v("Prices")]),_c('div',{staticClass:"text-white"},[_vm._v(" 1 "),_c('Ticker',{attrs:{"asset":_vm.pool.x_asset}}),_vm._v(" ≈ "),_c('Amount',{attrs:{"value":_vm.pool.getPrice(_vm.pool.x_asset, this.settings),"asset":_vm.pool.y_asset}}),_c('Ticker',{attrs:{"asset":_vm.pool.y_asset}})],1),_c('div',{staticClass:"text-white"},[_vm._v(" 1 "),_c('Ticker',{attrs:{"asset":_vm.pool.y_asset}}),_vm._v(" ≈ "),_c('Amount',{attrs:{"value":_vm.pool.getPrice(_vm.pool.y_asset, this.settings),"asset":_vm.pool.x_asset}}),_c('Ticker',{attrs:{"asset":_vm.pool.x_asset}})],1)]):_vm._e(),_c('label',{staticClass:"d-block"},[_vm._v("Pool size")]),_c('a',{attrs:{"href":_vm._explorerLink(_vm.pool.address),"target":"_blank"}},[_c('Amount',{attrs:{"value":_vm.pool.balances.xn,"asset":_vm.pool.x_asset}}),_c('Ticker',{attrs:{"asset":_vm.pool.x_asset}}),_vm._v(" + "),_c('Amount',{attrs:{"value":_vm.pool.balances.yn,"asset":_vm.pool.y_asset}}),_vm._v(" "),_c('Ticker',{attrs:{"asset":_vm.pool.y_asset}}),(_vm.pool.hasLiquidity() && _vm.pool.marketcap)?_c('span',{domProps:{"textContent":_vm._s(
          ` ≈ $${(+_vm.pool.marketcap.toFixed(2)).toLocaleString(undefined, {
            maximumFractionDigits: 18
          })}`
        )}}):_vm._e(),_c('Icon',{staticClass:"ml-1",attrs:{"name":"external-link","size":"18"}})],1)]),(_vm.share)?_c('div',[_c('label',{staticClass:"d-block"},[_vm._v("Your pool share")]),_c('span',{staticClass:"text-white"},[(_vm.share > 1)?_c('Pie',{staticClass:"mr-2",attrs:{"percent":_vm.share}}):_vm._e(),_vm._v(" "+_vm._s(_vm.share)+"% ")],1)]):_vm._e(),_c('div',[_c('label',{staticClass:"d-block"},[_vm._v("APY")]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('a',{attrs:{"href":_vm._statsLink(_vm.pool.address),"target":"_blank","title":"Go to stats website"}},[_vm._v(" "+_vm._s(_vm.apy)+"% "),_c('Icon',{staticClass:"ml-1",attrs:{"name":"external-link","size":"18"}})],1)]),(_vm.farmingAPY)?_c('div',{staticClass:"ml-2 text-white flex",staticStyle:{"font-size":"16px"}},[_c('span',{staticClass:"tooltipped tooltipped-n tooltipped-no-delay ml-1",staticStyle:{"display":"inline-block","margin-bottom":"4px"},attrs:{"aria-label":"Farming rewards from token.oswap.io. Click to go."}},[_c('a',{attrs:{"href":_vm.config.tokenFrontendUrl + '/farming',"target":"_blank"}},[_c('span',[_vm._v(" +"+_vm._s(_vm.farmingAPY)+"% ")]),_c('TooltipIcon',{staticStyle:{"margin-bottom":"1px"}})],1)])]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }