import { render, staticRenderFns } from "./AssetIcon.vue?vue&type=template&id=1e8b6024&scoped=true"
import script from "./AssetIcon.vue?vue&type=script&lang=js"
export * from "./AssetIcon.vue?vue&type=script&lang=js"
import style0 from "./AssetIcon.vue?vue&type=style&index=0&id=1e8b6024&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e8b6024",
  null
  
)

export default component.exports